import React, { useState, useCallback, useEffect } from "react";
import Paper from '@material-ui/core/Paper';
import {
  SearchState,
  FilteringState,
  IntegratedFiltering,
  TreeDataState,
  CustomTreeData,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
  TableFilterRow,
  TableTreeColumn,
  Toolbar,
  SearchPanel,
} from '@devexpress/dx-react-grid-material-ui';

import withLocation from '../../utils/withLocation'

const getChildRows = (row, rootRows) => {
  const childRows = rootRows.filter(r => r.parent === (row ? row.code : ''));
  return childRows.length ? childRows : null;
}

const Codelist = () => {
  const [columns] = useState([
    { name: 'code', title: 'Code' },
    { name: 'name', title: 'Name' },
    { name: 'included', title: 'Included Occupations', width: '1000' },
    { name: 'details', title: 'Job Titles', width: '1000' },
    { name: 'excluded', title: 'Excluded Occupations', width: '1000' },
  ]);
  const [rows, setRows] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [expandedRowIds, setExpandedRowIds] = useState([]);
  const [tableColumnExtensions] = useState([
    { columnName: 'code', width: 250 },
    { columnName: 'included', wordWrapEnabled: true },
    { columnName: 'details', wordWrapEnabled: true },
    { columnName: 'excluded', wordWrapEnabled: true },
  ]);

  const filteringStateColumnExtensions = [
    { columnName: 'code', filteringEnabled: false },
  ];

  const expandRowOnChange = useCallback(
    value => {
      const idsToExpand = rows
        .filter(row => {
          const children = getChildRows(row, rows);
          return children !== null && children.length;
        })
        .map(row => row.id);

      setSearchValue(value);
      setExpandedRowIds(idsToExpand);
    },
    [rows]
  );

  const loadData = () => {
    fetch("https://www.ilo.org/data-api/rest/v1/codelist/SCO/ISCO08")
        .then(response => response.json())
        .then(data => {
          setRows(data);
        })
        .catch(error => {
            /* eslint no-console: ["error", { allow: ["warn", "error"] }] */
            console.error(error)
        })
  };

  useEffect(() => loadData());

  return (
    <section className="section">
    <Paper>
      <Grid rows={rows} columns={columns}>
        <SearchState value={searchValue} onValueChange={expandRowOnChange} />
        <FilteringState columnExtensions={filteringStateColumnExtensions} />
        <TreeDataState
          expandedRowIds={expandedRowIds}
          onExpandedRowIdsChange={setExpandedRowIds}
        />
        <CustomTreeData getChildRows={getChildRows} />
        <IntegratedFiltering />
        <VirtualTable columnExtensions={tableColumnExtensions} />
        <TableHeaderRow />
        <TableFilterRow />
        <TableTreeColumn for="code" />
        <Toolbar />
        <SearchPanel />
      </Grid>
    </Paper>
    </section>
  );
};

export default withLocation(Codelist)

// import ToTree from '../../utils/convertToTree'
// import axios from 'axios'
//import { MDBDataTableV5 } from 'mdbreact';

// import '@fortawesome/fontawesome-free/css/all.min.css'
// import 'bootstrap-css-only/css/bootstrap.min.css'
// import 'mdbreact/dist/css/mdb.css'

// import Layout from '../../components/Layout'
// import SEO from '../../components/SEO/SEO'
// import jsonData from '../../data/articles/articles.json'

// setup config for api request
// const config = {
//   method: 'get',
//   url: 'https://www.ilo.org/sdmx/rest/codelist/ILO/CL_OCU',
//   headers: {
//     // eslint-disable-next-line quote-props
//     'Accept': 'application/vnd.sdmx.structure+json;version=1.0',
//     'Accept-Language': 'en',
//   },
// }

// const config = {
//   method: 'get',
//   url: 'https://www.ilo.org/data-api/rest/v1/codelist/SCO/ISCO08',
// }

// class Codelist extends React.Component {
//   constructor(props) {
//     super(props)

//     this.state = {
//       codes: [],
//       expandedRowIds: [],
//       searchValue: '',
//     }
//   }

//   componentDidMount() {
//     this.getCodelist()
//   }

//   getCodelist = async() => {
//     // fetch CL_OCU codelist from SDMX, once it retrieves resolve the promsie and update the state
//     await axios(config).then((response) => {
//       if (response.status === 200) {
//         this.setState({ codes: ToTree(response.data) })
//       }
//     }).catch(error => {
//       /* eslint no-console: ["error", { allow: ["warn", "error"] }] */
//       console.error(error)
//     })
//   }

//   render() {
//     const { codes } = this.state
//     const { expandedRowIds, searchValue } = this.state

//     const getChildRows = (row, rootRows) => (row ? row.children : rootRows);
//     // const getChildRows = (row, rootRows) => {
//     //   //console.log(row)
//     //   const childRows = rootRows.filter(r => r.parent === (row ? row.code : ''));
//     //   //console.log(childRows)
//     //   return childRows.length ? childRows : null;
//     // };

//     // const data = {
//     //   columns: [
//     //     { name: 'code', title: 'Code' },
//     //     { name: 'name', title: 'Name' },
//     //     { name: 'includedOccupations', title: 'Included Occupations' },
//     //     { name: 'excludedOccupations', title: 'Excluded Occupations' }
//     //   ],
//     //   rows: codes,
//     // }

//     const columns = [
//       { name: 'code', title: 'Code' },
//       { name: 'name', title: 'Name' },
//       { name: 'included', title: 'Included Occupations', width: '1000' },
//       { name: 'details', title: 'Job Titles', width: '1000' },
//       { name: 'excluded', title: 'Excluded Occupations', width: '1000' },
//     ];
//     const data = codes;
//     const tableColumnExtensions = [
//       { columnName: 'code', width: 250 },
//       { columnName: 'included', wordWrapEnabled: true },
//       { columnName: 'details', wordWrapEnabled: true },
//       { columnName: 'excluded', wordWrapEnabled: true },
//     ];

//     const filteringStateColumnExtensions = [
//       { columnName: 'code', filteringEnabled: false },
//     ];

//     // const expandedRowIds, setExpandedRowIds = [];

//     const expandRowOnChange = () => {
//       //console.log(data)
//       value => {
//         const idsToExpand = data
//           .filter(row => {
//             const children = getChildRows(row, data);
//             return children !== null && children.length;
//           })
//           .map(row => row.id);
  
//         //setSearchValue(value);
//         //setExpandedRowIds(idsToExpand);

//         this.setState({ searchValue: value })
//         this.setState({ expandedRowIds: idsToExpand })
//       },
//       [data]
//     };

//     // const searchColumns = [
//     //   { name: 'code', title: 'Code' },
//     //   { name: 'name', title: 'Name' },
//     //   { name: 'included', title: 'Included Occupations' },
//     //   { name: 'details', title: 'Job Titles' },
//     // ];

//     // const defaultExpandedRowIds = [0, 1, 2, 7, 9, 10];

//     //const [searchValue, setSearchState] = 'Female';

//     // var dataMarkdown = [];
//     // if (this.props.data !== null) {
//     //   dataMarkdown = this.props.data.markdownRemark
//     // }
//     // const jsonData = this.props.data.allArticlesJson.edges[0].node.articles;
//     // const { frontmatter, fields } = dataMarkdown;
//     // const image = frontmatter.image.childImageSharp.fluid.src;
//     // const langKey = frontmatter.lang;
//     // const tags = frontmatter.tags;

//     return (
//       // <Layout className="container" data={this.props.data} jsonData={jsonData} location={this.props.location}>
//       //   <SEO title="ISCO-08 Code List" />
//         <section className="section">
//           <Paper>
//             <Grid
//               rows={data}
//               columns={columns}
//             >
//               <SearchState value={searchValue} onValueChange={expandRowOnChange} />
//               <FilteringState columnExtensions={filteringStateColumnExtensions} />
//               <TreeDataState
//                 expandedRowIds={expandedRowIds}
//                 onExpandedRowIdsChange={setExpandedRowIds}
//               />
//               <CustomTreeData getChildRows={getChildRows} />
//               <IntegratedFiltering />
//               <Table columnExtensions={tableColumnExtensions} />
//               <TableHeaderRow />
//               <TableFilterRow />
//               <TableTreeColumn for="code" />
//               <Toolbar />
//               <SearchPanel />
//             </Grid>
//           </Paper>
//           {/* <MDBDataTableV5
//             striped
//             bordered
//             small
//             searchTop searchBottom={false}
//             data={data}
//           >
//           </MDBDataTableV5> */}
//           {/* {codes.map((code) => {
//             return (
//               <div key={code.code}><br />
//                 <p key={code.code} style={{ marginLeft: "25px" }}>{`${code.code} - ${code.name}`}</p>
//                 <ul>
//                 {code.children.map((child1) => {
//                   return (
//                     <li key={child1.code} style={{ marginLeft: "50px", listStyleType: "disc" }}>{`${child1.code} - ${child1.name}`}
//                       <ul>
//                         {child1.children.map((child2) => {
//                           return (
//                             <li key={child2.code} style={{ marginLeft: "50px", listStyleType: "circle" }}>{`${child2.code} - ${child2.name}`}
//                               <ul>
//                                 {child2.children.map((child3) => {
//                                   return <li key={child3.code} style={{ marginLeft: "50px", listStyleType: "square" }}>{`${child3.code} - ${child3.name}`}</li>
//                                 })}
//                               </ul>
//                             </li>
//                           )
//                         })}
//                       </ul>
//                     </li>
//                   )
//                 })}
//                 </ul>
//               </div>
//             )
//           })} */}
//         </section>
//       // </Layout>
//     )
//   }
// }

// export default withLocation(Codelist)
